module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"owyhee-roofing","short_name":"owyhee","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon-96x96.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"406ca0dac685947c214e5f3c1192a985"},
    },{
      plugin: require('../node_modules/@twicpics/components/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"twicpics":{"domain":"https://boisewebdesign.twic.pics"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7YVQ8PS427"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
